<template>
    <AuthForm title="Forgot your password?" link="Back to login" klass="mt-24">
      <form class="mt-8 space-y-6" action="#" method="POST">
        <input type="hidden" name="remember" value="true" />
        <div class="rounded-md shadow-sm">
          <div>
            <label
              for="email-address"
              class="
                block
                mb-2
                text-sm
                font-medium
                text-gray-900
                dark:text-gray-300
              "
              >Your Email</label
            >
            <input
              id="email-address"
              name="email"
              type="email"
              autocomplete="off"
              required
              class="
                appearance-none
                rounded-none
                relative
                block
                w-full
                px-3
                py-2
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-t-md
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
              "
              placeholder="Email address"
            />
          </div>
        </div>
        <div>
          <button
            type="submit"
            class="
              group
              relative
              w-full
              flex
              justify-center
              py-2
              px-4
              border border-transparent
              text-sm
              font-medium
              rounded-md
              text-white
              bg-indigo-600
              hover:bg-indigo-700
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-indigo-500
            "
          >
            Send Reset Link
          </button>
        </div>
      </form>
    </AuthForm>
</template>

<script lang="js">
import AuthForm from "@/components/AuthForm";
import Loader from "@/components/Loader";

export default {
    name: "Login",
    components: {
        AuthForm,
    },
    data() {
        return {
            user: {
                email: ''
            },
            errors: {},
            loading: false
        }
    },
}
</script>
